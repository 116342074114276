<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2">
            Cobertura de domicilios
        </div>
        <div class="p-3 f-15">
            <div class="row mx-0 my-2">
                En la siguiente gráfica puede visualizar el rango de cobertura de los domicilios por el número de tiendas.
            </div>
            <div class="row mx-0 my-3 align-items-center">
                <p>Cobertura de las tiendas:</p>
                <div class="col-auto">
                    <el-tooltip class="item" effect="light" content="Tiendas registradas" placement="bottom">
                        <div class="br-20 bg-gr-general d-middle text-white justify-content-between" style="min-width:57px;">
                            <i class="icon-storefront-outline mx-1  f-18" />
                            <span class="pr-2">0</span>
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto">
                    <div class="br-20 bg-gr-red d-middle text-white justify-content-between" style="min-width:57px;">
                        <i class="icon-ruler mx-1  f-18" />
                        <span class="pr-2">Hasta 5.000 m</span>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-2">
                <div class="col position-relative">
                    <echart :options="chartCobertura" />
                    <div class="img-sindatos text-center" style="max-width:230px;">
                        No hay tiendas registradas que hayan configurado su cobertura
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-general2')
export default {
    data(){
        return {
            chartCobertura: {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#DBDBDB',
                            type: 'dashed'
                        }
                    },
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    symbol:'circle',
                    symbolSize: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: '#FF9D32' },
                                {offset: 1, color: '#FF2525' },
                            ]
                        )
                    },
                    lineStyle: {
                        color: colorGeneral
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.3, color: colorGeneral+21},
                                {offset: 0.2, color: colorGeneral2+21 },
                                {offset: 1, color: '#fff' },
                            ]
                        )
                    }
                }]
            }
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    .img-sindatos{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
