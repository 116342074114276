<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2">
            Tiempos de estados de los pedidos
        </div>
        <div class="p-3 f-15">
            <div v-for="(data, idx) in tiempos" :key="idx" class="row mx-0 my-3 align-items-center">
                <div class="col-7">
                    <p>{{ data.descripcion }}</p>
                </div>
                <div class="col-2 px-2">
                    <el-input v-model="data.min" />
                </div>
                <p>Minutos</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            tiempos: [
                { descripcion: 'Tiempo máximo para aceptar una solicitud de pedido antes de que se cancele automáticamente', min: 0 },
                { descripcion: 'Tiempo máximo para enviar un pedido después de aceptado (si no se envía en ese rango de tiempo el pedido se cancela automáticamente)', min: 0 },
                { descripcion: 'Tiempo que permanece activo el chat después de que el pedido ha sido enviado', min: 0 },
                { descripcion: 'Tiempo en el que será posible realizar reclamos después de que el pedido ha sido enviado', min: 0 },
                { descripcion: 'Tiempo en el que el cliente podrá solicitar soportedespués del cierre del reclamo', min: 0 },
                { descripcion: 'Tiempo en el que será posible aceptar los cambios del tendero o añadir nuevos productos por parte del clientes (Si no, se aceptaran automáticamente)', min: 0 }
            ]
        }
    }
}
</script>
