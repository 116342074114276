<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2">
            Motivos de inactividad
        </div>
        <div class="p-3 f-15">
            <div class="row mx-0">
                Crea los diferentes motivos por los cuales puede inactivar una tienda
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-2">
                    <div class="col-3 px-2">
                        <ValidationProvider v-slot="{errors}" rules="required" name="tipo">
                            <p class="ml-2">Motivo</p>
                            <el-input :suffix-icon="false?'icon-attention-alt text-danger':''" v-model="input1" />
                            <span v-show="false" class="text-danger w-100 f-11"> Ya existe un motivo con este nombre </span>
                            <span class="text-danger w-100 f-11"> {{errors[0]}} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto mt-3 pt-1">
                        <el-tooltip class="item" effect="light" content="Crear unidad de medida" placement="bottom">
                            <div class="bg-grr-general cr-pointer d-middle-center br-10" style="width:44px;height:44px;">
                                <i class="icon-plus f-20 text-white" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <hr />
            <div class="row mx-0 my-2">
                Lista de motivos
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <draggable :list="criterios" handle=".handle">
                    <div v-for="(data, idx) in 4" :key="data" class="row mx-0 my-1">
                        <div class="col-3 px-2" :class="value2?'bg-disabled':'bg-inactive'">
                            <ValidationProvider v-slot="{errors}" rules="required" name="tipo">
                                <el-input :disabled="editar!=idx" :suffix-icon="false?'icon-attention-alt text-danger':''" v-model="input1" />
                                <span v-show="false" class="text-danger w-100 f-11"> Ya existe una U.M con este nombre </span>
                                <span class="text-danger w-100 f-11"> {{errors[0]}} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto px-2">
                            <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                                <div class="d-middle-center btn-edit br-10" style="width:44px;height:44px;">
                                    <i class="icon-pencil-outline f-18 cr-pointer" @click="editar=idx" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                <div class="bg-general2 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="guardar(idx)">
                                    <i class="icon-ok-circled-outline text-white f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-0">
                            <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                <div class="d-middle-center btn-delete cr-pointer br-10" style="width:44px;height:44px;" @click="eliminar()">
                                    <i class="icon-trash-empty f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-2 mt-2 pt-1">
                            <el-switch
                            class="my-auto switch-red"
                            v-model="value2"
                            active-color="red"
                            inactive-color="#DBDBDB"
                            />
                        </div>
                        <div class="col-auto pt-2">
                            <el-tooltip class="item" effect="light" placement="bottom">
                                <div slot="content" class="" style="max-width:150px;">
                                    Pedidos que ha sacado este criterio para calificar
                                </div>
                                <div class="br-20 bg-gr-general d-middle text-white justify-content-between" style="min-width:57px;">
                                    <i class="icon-storefront-outline mx-1  f-18" />
                                    <span class="pr-2">0</span>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </draggable>
            </ValidationObserver>
            <div class="row mx-0 my-3 justify-content-center">
                <div class="my-4 text-center" style="max-width:170px;">
                    No se ha creado ningún motivo
                </div>
            </div>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar motivo" mensaje="¿Desea eliminar el tipo de inactividad?" />
    </section>
</template>

<script>
let colorDegRed = getComputedStyle(document.documentElement).getPropertyValue('--gr-red')
export default {
    data(){
        return {
            value2: true,
            editar: -1,
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: 'LA',
            input1: ''
        }
    },
    methods: {
        guardar(idx){
            this.editar=-1
        },
        eliminar(){
            this.$refs.modalEliminar.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
