<template>
    <section>
        <div class="row mx-0">
            <div class="border menu-prinicipal br-10" style="width:430px;">
                <div
                v-for="(menu, idx) in menus" :key="idx"
                class="row mx-0 d-middle border-bottom cr-pointer"
                style="height:60px;"
                @click="$router.push({ name: menu.ruta })"
                >
                    <div :class="`mx-2 px-1 h-80 br-5 ${menu.ruta==$route.name?'bg-gr-red':''}`" />
                    <i :class="`${menu.icon} f-22 text-gr-general`" />
                    <div class="col-9 tres-puntos f-15">
                        {{ menu.nombre }}
                    </div>
                    <i :class="`icon-angle-right f-18 ${menu.ruta==$route.name?'text-gr-red':''}`" />
                </div>
            </div>
            <div class="px-0 border br-t-10 menu-prinicipal ml-3" style="width: calc(100vw - 595px);">
                <!-- <component :is="activo" /> -->
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            activo: 'tipoTiendas',
            menus: [
                { nombre: 'Tipo de tiendas', ruta: 'admin.conf.tipoTiendas', icon: 'icon-format-list-bulleted-type' },
                { nombre: 'Distancia de los domicilios', ruta: 'admin.conf.distancia', icon: 'icon-ruler' },
                { nombre: 'Cobertura por defecto de domicilios', ruta: 'admin.conf.cobertura', icon: 'icon-bike' },
                { nombre: 'Tiempos de estados de los pedidos', ruta: 'admin.conf.tiempos', icon: 'icon-clock-end' },
                { nombre: 'Motivos de inactividad', ruta: 'admin.conf.motInactivo', icon: 'icon-account-reactivate' },
                { nombre: 'Criterios de calificación de pedidos', ruta: 'admin.conf.criterios', icon: 'icon-star' },
                { nombre: 'Motivos de rechazo por tendero', ruta: 'admin.conf.motRechazo', icon: 'icon-playlist-remove' },
                { nombre: 'Motivos de cancelación por tendero', ruta: 'admin.conf.motCanTendero', icon: 'icon-cancel-circled-outline' },
                { nombre: 'Motivos de cancelación por cliente', ruta: 'admin.conf.motCanCliente', icon: 'icon-account-remove-outline' },
                { nombre: 'Grupo de avatares', ruta: 'admin.conf.grupoAvatares', icon: 'icon-sticker-emoji' },
            ]
        }
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    height: calc(100vh - 58px);
    background: #fff;
}
</style>
