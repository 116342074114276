<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2">
            Grupo de avatares
        </div>
        <div class="p-3 f-15">
            <div class="row mx-0">
                Selecciona los grupos de avatares de los cuales los clientes podrán escoger su prefedido
            </div>
            <div v-for="grupo in grupos" class="">
                <div
                class="row mx-0 br-10 align-items-center px-3 py-1 cr-pointer mt-3"
                :class="grupo.check?'bg-gr-general text-white':'bg-whitesmoke'"
                @click="grupo.check=!grupo.check"
                >
                    <i :class="`f-22 mr-2 ${grupo.check?'icon-ok-squared':'icon-check-empty'}`" />
                    <p>{{grupo.nombre}}</p>
                </div>
                <div class="row mx-0 p-3">
                    <img v-for="data in 10" :key="data" class="rounded-circle m-2 border bg-white obj-cover" width="65" height="65" src="https://image.freepik.com/vector-gratis/linda-feliz-divertida-galleta-chocolate-taza-cafe-diseno-icono-ilustracion-personaje-dibujos-animados_92289-1185.jpg" alt="" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            grupos: [
                { nombre: 'Grupo 1', check: true },
                { nombre: 'Grupo 2', check: false }
            ]
        }
    }
}
</script>

<style lang="css" scoped>
</style>
